import {
  EvaluationCriteria,
  EvaluationCriteriaConfig,
  EvaluationRelevantInstance,
  EvaluationResults,
} from "@/types/EvaluationResults";
import { doc, updateDoc } from "firebase/firestore";
import { storeToRefs } from "pinia";
import { EvaluationResultsStatus } from "~/types/enums/EvaluationResultsStatus";
import { evaluateCriteriaTree } from "~/utils/evaluation.utils";

// @ts-ignore
import { docData } from "rxfire/firestore";
import { CriteriaInstance } from "~/types/Checklist";

import { DocumentSubmissionType } from "~/types/enums/DocumentSubmissionType.enum";

export const useEvaluationResults = (
  documentId: string,
  revisionId: string,
  checklistSectionId: string,
  checklistCriteriaId: string,
  criteriaInstance: CriteriaInstance
) =>
  definePiniaStore(
    `evaluation-results/${documentId}/${revisionId}/${checklistSectionId}/${checklistCriteriaId}`,
    () => {
      const documentIdRef = ref<string>(documentId);
      const revisionIdRef = ref<string>(revisionId);
      const checklistCriteriaIdRef = ref<string>(checklistCriteriaId);

      const submittedDocumentStore = useSubmittedDocumentStore(documentId);

      const documentChecklistStore = useDocumentChecklistStore(documentId);
      const { saveChecklist } = documentChecklistStore;
      const {
        useChecklist,
        mechanicsCriteria,
        mechanicsAnnotations,
        numExpectedBodyParagraphs,
        hasCorrectNumberOfBodyParagraphs,
      } = storeToRefs(documentChecklistStore);

      const documentEvaluativeParagraphsStore =
        useDocumentEvaluativeParagraphsStore(documentId);
      const {
        numBodyParagraphs,
        evaluativeParagraphIdToPositionMap,
        relevantInstanceToParagraphDataMap,
      } = storeToRefs(documentEvaluativeParagraphsStore);

      // Evaluation Criteria Instance
      const evaluationCriteriaInstanceStore = useEvaluationCriteriaInstance(
        documentId,
        revisionId,
        checklistSectionId,
        checklistCriteriaId,
        criteriaInstance
      )();
      const { criteriaParagraphPositionsData, criteriaParagraphPositions } =
        storeToRefs(evaluationCriteriaInstanceStore);

      const evaluationResultsRef = computed(() => {
        const db = useFirestore();
        return doc(
          db,
          `/documents/${documentIdRef.value}/revisions/${revisionIdRef.value}/evaluationResults/${checklistCriteriaIdRef.value}`
        );
      });

      const evaluationResultsObservable = computed(() => {
        return docData(evaluationResultsRef.value);
      });

      // OK Firt thing, let's figure out how to generate the computed earned points
      // This is the earned poitns computed from the criteria tree results indepemndent o
      // of the user's manual entries.
      const computedEarnedPoints = ref<number | undefined>();
      const manualPoints = ref<number | undefined>(
        criteriaInstance.manualPoints
      );

      // First we will check if there are paragraph points for this criteria and if there are,
      // thsoe are used to compute the earned points.
      // We calculate earned points based on the manual points if they exist, otherwise
      // we use the computed earned poitns
      const earnedPoints = computed(() => {
        const method = criteriaInstance.evaluationMethod;
        const checklistCriteria =
          documentChecklistStore.getCriteriaFromId(checklistCriteriaId);

        return useEarnedPoints(
          manualPoints.value,
          computedEarnedPoints.value,
          method,
          criteriaParagraphPositionsData.value,
          checklistCriteria,
          criteriaInstance,
          mechanicsCriteria.value,
          mechanicsAnnotations.value
        );
      });

      watch(mechanicsAnnotations, (oldValue, newValue) => {
        if (
          checklistCriteria.value?.isMechanicsCriteria &&
          oldValue.length != newValue.length
        ) {
          updateManualPoints(undefined);
        }
      });

      const saveCriteriaStatisticTransaction = async () => {
        console.log("Saving criteria statistic transaction");
        // If this document has a submission type and it's not a final draft then we will not log this statistic
        const documentSubmissionType =
          submittedDocumentStore.submittedDocument?.documentSubmissionType;

        if (
          documentSubmissionType == undefined ||
          documentSubmissionType != DocumentSubmissionType.finalDraft
        ) {
          return;
        }

        await useSaveCriteriaStatisticTransaction(
          submittedDocumentStore.documentId,
          submittedDocumentStore.revision,
          submittedDocumentStore.submittedDocument,
          useChecklist.value,
          documentChecklistStore.getCriteriaFromId(checklistCriteriaId),
          checklistSectionId,
          earnedPoints.value,
          criteriaInstance
        );
      };

      // Next we want to have logic to compute the scores
      // This would be triggered every time evaluationResults emit changes.
      // This allows us to stream our evaluation results.
      const { data, pending, error } =
        useDocument<EvaluationResults>(evaluationResultsRef);

      const evaluationResults = ref<EvaluationResults | undefined>();

      // Every time our evaluation results change we want to:
      // - reevaluate the tree
      // - update the computed points for both paragraphs or the total score
      watch(data, () => {
        const results = data.value as EvaluationResults | undefined;
        if (!results) return;

        if (
          !evaluationResults.value ||
          evaluationResults.value.status !== results.status
        ) {
          evaluationResults.value = results;
        }

        if (
          evaluationResults.value.status !== EvaluationResultsStatus.success
        ) {
          return;
        }

        // requiresInstanceReview.value =
        //   evaluationResults.value.requiresInstanceReview ?? false;
        // hasReviewedInstances.value =
        //   evaluationResults.value.hasReviewedInstances ?? false;

        processCriteria();

        saveCriteriaStatisticTransaction();
      });

      watch(evaluativeParagraphIdToPositionMap, () => {
        processCriteria();
      });

      watch(numBodyParagraphs, () => {
        processCriteria();
      });

      // We wathc our earned points computed value and if it ever changes we update the criteria instance
      // and save the checklist.
      watch(earnedPoints, () => {
        criteriaInstance.earnedPoints = earnedPoints.value;
        saveCriteriaStatisticTransaction();
        saveChecklist();
      });

      // We will also watch changes to manualPoints and update the criteira
      watch(manualPoints, () => {
        criteriaInstance.manualPoints = manualPoints.value;
        saveChecklist();
      });

      // If the mapping of paragraph positions to body paragrap hpositions changes
      // We want to reinfer
      watch(relevantInstanceToParagraphDataMap, () => {
        inferParagraphPosition();
      });

      // Again, we watch the earnedParagraphPoints so that if the value changes, we can
      // set the criteria instance and save the checklist.
      watch(criteriaParagraphPositionsData, () => {
        saveEarnedParagraphPoints();
      });

      const saveEarnedParagraphPoints = () => {
        saveChecklist();
      };

      const processCriteria = () => {
        if (evaluationResults.value == undefined) return;

        evaluateCriteriaTree(evaluationResults.value);
        sortRelevantInstances();
        populateRelevanInstanceToParagraphPositionMap();
        inferParagraphPosition();
        calculateComputedPoints();
      };

      const populateRelevanInstanceToParagraphPositionMap = () => {
        const instances = relevantInstances.value;

        for (const instance of instances) {
          if (instance.bodyParagraphPosition == undefined) continue;

          useDocumentEvaluativeParagraphsStore(
            documentId
          ).assignRelevantInstanceToParagraphPosition(
            instance,
            instance.bodyParagraphPosition
          );
        }
      };

      const saveEvaluationResults = async () => {
        await updateDoc(evaluationResultsRef.value, evaluationResults.value);
      };

      const sortRelevantInstances = () => {
        if (criteriaInstance.evaluationMethod != "paragraph") return;

        if (criteriaParagraphPositionsData == undefined) return;

        if (useIsEvaluationResultsValid(evaluationResults.value) != true)
          return;

        const relevantInstances =
          evaluationResults.value!.evaluationResult[0].relevantInstances;

        useSortRelevantInstances(relevantInstances);
      };

      // In this function we want to see if we can allocate a paragraph position to
      // each relevant instance in the first layer of the criteria tree
      const inferParagraphPosition = () => {
        const instances = relevantInstances.value;

        if (requiresInstanceReview.value != true) {
          useInferInstanceBodyParagraphPositions(
            documentId,
            criteriaInstance,
            criteriaParagraphPositionsData.value,
            evaluationResults.value,
            hasCorrectNumberOfBodyParagraphs.value,
            evaluativeParagraphIdToPositionMap.value
          );

          return;
        }

        for (const instance of instances) {
          if (instance.bodyParagraphPosition != undefined) continue;

          const position =
            useDocumentEvaluativeParagraphsStore(
              documentId
            ).getPositionForRelevantInstance(instance);

          if (position) {
            instance.bodyParagraphPosition = position;
          }
        }
      };

      // Here we compute the points for a particular kind of criteria.
      // If the criteria isMechanics then it should add to it the global mechanics
      const calculateComputedPoints = () => {
        const results = evaluationResults.value;

        if (!results || !results.evaluationResult) {
          return;
        }

        // So her ehwat we do is if we're using the new mode then instead what we're going to is start from
        // The criterriaParagraphs and calculate the score for each one of thoe.
        // For certain kind of criteria, we want
        if (criteriaInstance.evaluationMethod == "paragraph") {
          if (criteriaParagraphPositionsData.value == undefined) return;

          var pointsPerInstance = criteriaInstance.pointsPerInstance ?? 0;

          let totalPoints = 0;
          for (const paragraphPositionData of criteriaParagraphPositionsData.value) {
            var instances = [
              ...relevantInstancesForParagraph.value(
                paragraphPositionData.position
              ),
            ];

            // We now only want to look at the first instance for the auto score suggest
            // I want to remove all items after the first from this array.
            // We only want to do this if showAllInstances is false
            if (
              criteriaConfig.value?.shouldHideExtraInstances == true &&
              showAllInstances.value(paragraphPositionData.position) == false
            ) {
              instances = instances.slice(0, 1);
            }

            var points = 0;
            for (var instance of instances) {
              if (
                results.evaluationResult[0].config.isManual != true &&
                instance.valid == true &&
                // If we are configured to suppres points deduction then we shouldn't change the points calcuation
                criteriaInstance.suppressPointsDeductions != true
              ) {
                points = pointsPerInstance;
              }
            }

            paragraphPositionData.score = points;
            totalPoints += points;
          }

          criteriaParagraphPositionsData.value = [
            ...criteriaParagraphPositionsData.value,
          ];

          computedEarnedPoints.value = Math.min(
            totalPoints,
            criteriaInstance.maxPoints ?? 0
          );

          return;
        }

        // Can I just get rid of htis an assume eveything has paragraph points stuff?
        if (results.evaluationResult.length > 0) {
          let numCorrectInstances = 0;
          var pointsPerInstance = criteriaInstance.pointsPerInstance ?? 0;

          for (const evaluationResult of results.evaluationResult) {
            var instances = evaluationResult.relevantInstances;

            for (const key in instances) {
              var instance = instances[key];
              var points = 0;

              const requiresManualReview =
                evaluationResult.config.isManual ||
                (evaluationResult.config.manualInstanceReview &&
                  instance.isReviewed != true);

              if (
                requiresManualReview != true &&
                instance.valid &&
                criteriaInstance.suppressPointsDeductions != true
              ) {
                numCorrectInstances++;
                points = pointsPerInstance;
              }
            }
          }

          computedEarnedPoints.value = Math.min(
            pointsPerInstance * numCorrectInstances,
            criteriaInstance.maxPoints ?? 0
          );
        }
      };

      // Now we need some utility functions, first to update the manual scores.
      const updateManualPoints = (newValue: string | number | undefined) => {
        let value = newValue;

        if (typeof value == "string") {
          value = parseInt(value);

          if (isNaN(value)) {
            return;
          }
        }

        reviewed.value = true;
        manualPoints.value = value;
      };

      // If the manualParagraphPoints doesn't exist then we should create it
      // If we're not passing new points, that means we're deleting a manual score
      // Otherwise, we're updating the manual score at the specified index
      const updateManualParagraphPoints = (
        paragraphPosition: number,
        newValue: string | number | undefined
      ) => {
        let value = newValue;

        if (typeof value == "string") {
          value = parseInt(value);

          if (isNaN(value)) {
            return;
          }
        }

        if (criteriaParagraphPositionsData.value == undefined) return;

        const paragraphPositionData = criteriaParagraphPositionsData.value.find(
          (p) => p.position == paragraphPosition
        );

        if (!paragraphPositionData) return;

        if (value == undefined) {
          delete paragraphPositionData.manualScore;
          return;
        }

        reviewed.value = true;
        paragraphPositionData.manualScore = value;
      };

      // This can be used to identify that the user has interacted with the evaluation results
      const reviewed = ref<boolean>(criteriaInstance.reviewed ?? false);

      // If there's a change in reviewed status, we will update the criteria
      // This is important to make sure we track which results a user has already acknowledge.
      watch(reviewed, () => {
        criteriaInstance.reviewed = reviewed.value;
        saveChecklist();
      });

      // A user can accept the results as is in which case we will want to mark the criteria as reviewed.
      const acceptEvaluation = () => {
        reviewed.value = true;
      };

      const reevaluate = async (bypassCache?: boolean) => {
        // We'll clear the manual scores whenever reevaluating
        manualPoints.value = undefined;
        evaluationCriteriaInstanceStore.resetPoints();

        useEvaluateChecklistCriteria(
          documentId,
          revisionId,
          checklistCriteriaId,
          true,
          bypassCache
        );
      };

      // Additional utility functions to help us determine the state of the criteria
      const hasNoScore = computed(() => {
        return !manualPoints.value && !computedEarnedPoints.value;
      });

      const hasAISuggestions = computed(() => {
        return (
          evaluationResults.value &&
          evaluationResults.value.status != EvaluationResultsStatus.failed
        );
      });

      const isLoadingResults = computed(() => {
        if (!hasAISuggestions.value) {
          return false;
        }

        return (
          evaluationResults.value?.status == EvaluationResultsStatus.evaluating
        );
      });

      const requiresReview = computed(() => {
        if (reviewed.value) {
          return false;
        }

        for (const result of evaluationResults.value?.evaluationResult ?? []) {
          if (result.config.manualInstanceReview == true) {
            return true;
          }
        }

        if (!hasAISuggestions.value) {
          return false;
        }

        if (manualPoints.value) {
          return false;
        }

        // Not sure what htis is for?
        // if (
        //   criteriaInstance.evaluationMethod == "paragraph"
        // ) {
        //   return false;
        // }

        if (computedEarnedPoints.value == undefined) {
          return false;
        }

        if (criteriaInstance.maxPoints == undefined) {
          return false;
        }

        return computedEarnedPoints.value < criteriaInstance.maxPoints;
      });

      // Here we have some state values that will allow us to track if paragraph criteria require reiew and hae been reviewed
      const requiresInstanceReview = computed(() => {
        if (hasMetric.value != true) return false;

        return numBodyParagraphs.value != numExpectedBodyParagraphs.value;
      });

      const hasReviewedInstances = ref(false);

      const relevantInstances = computed(() => {
        if (
          !evaluationResults.value ||
          !evaluationResults.value.evaluationResult ||
          evaluationResults.value.evaluationResult.length == 0
        )
          return [] as EvaluationRelevantInstance[];

        return evaluationResults.value?.evaluationResult[0].relevantInstances;
      });

      /// Here given a particular paragraph index, we want to return relevant instnaces wit that position
      const relevantInstancesForParagraph = computed(() => {
        return (bodyParagraphPosition: number) => {
          const instances = allocatedRelevantInstances.value.filter(
            (relevantInstance) =>
              relevantInstance.bodyParagraphPosition == bodyParagraphPosition
          );

          instances.sort((a, b) => {
            // If confidenceScore is equal, sort by the valid property (true before false)
            if (a.valid && !b.valid) {
              return -1;
            }
            if (!a.valid && b.valid) {
              return 1;
            }

            // Default confidenceScore to 0 if not present
            const scoreA = a.confidenceScore ?? 0;
            const scoreB = b.confidenceScore ?? 0;

            // First, sort by confidenceScore
            if (scoreA !== scoreB) {
              return scoreB - scoreA; // Descending order
            }

            return 0; // If both are equal in terms of score and validity
          });

          return instances;
        };
      });

      // Here we want to get instances that, in the context of a paragraph, are unallocated
      // Esssentially we just want to exclude instances that are allocated to a different paragraph
      const unallocatedInstancesForParagraph = computed(() => {
        return (bodyParagraphPosition: number) => {
          return relevantInstances.value.filter(
            (relevantInstance) =>
              relevantInstance.bodyParagraphPosition == bodyParagraphPosition ||
              relevantInstance.bodyParagraphPosition == undefined
          );
        };
      });

      const allocatedRelevantInstances = computed(() => {
        return relevantInstances.value.filter(
          (relevantInstance) =>
            relevantInstance.bodyParagraphPosition != undefined &&
            criteriaParagraphPositions.value != undefined &&
            criteriaParagraphPositions.value.includes(
              relevantInstance.bodyParagraphPosition
            )
        );
      });

      // Set the position of an instance
      // Can also be used to unset a position
      const setInstancePosition = (
        instance: EvaluationRelevantInstance,
        bodyParagraphPosition: number | undefined
      ) => {
        if (bodyParagraphPosition == undefined) {
          delete instance.bodyParagraphPosition;
        } else {
          instance.bodyParagraphPosition = bodyParagraphPosition;
        }

        useDocumentEvaluativeParagraphsStore(
          documentId
        ).assignRelevantInstanceToParagraphPosition(
          instance,
          bodyParagraphPosition
        );
      };

      const setHasReviewedInstances = (value: boolean) => {
        hasReviewedInstances.value = value;
        // requiresInstanceReview.value = !value;

        // if (evaluationResults.value) {
        //   evaluationResults.value.requiresInstanceReview =
        //     requiresInstanceReview.value;
        //   evaluationResults.value.hasReviewedInstances =
        //     hasReviewedInstances.value;

        //   saveEvaluationResults();
        // }
      };

      const criteriaConfig = computed(
        (): EvaluationCriteriaConfig | undefined => {
          if (
            !evaluationResults.value ||
            !evaluationResults.value.evaluationResult
          )
            return undefined;

          return evaluationResults.value?.evaluationResult[0].config;
        }
      );

      const didFailEvaluation = computed(() => {
        if (!evaluationResults.value) return false;

        if (!evaluationResults.value.evaluationResult) return false;

        return evaluationResults.value.status == EvaluationResultsStatus.failed;
      });

      const getInstanceFromToken = (token: ParagraphToken) => {
        if (!evaluationResults.value) return;

        if (!evaluationResults.value.evaluationResult) return;

        const instance =
          evaluationResults.value.evaluationResult[0].relevantInstances.find(
            (instance) => instance.tokens.find((t) => t.id == token.id)
          );

        return instance;
      };

      const checklistCriteria = computed(() => {
        return documentChecklistStore.getCriteriaFromId(checklistCriteriaId);
      });

      const hasMetric = computed(() => {
        const checklistCriteria =
          documentChecklistStore.getCriteriaFromId(checklistCriteriaId);

        if (!checklistCriteria) return false;

        return checklistCriteria.evaluationMetricId != undefined;
      });

      const reportProblem = async (
        problem: any,
        isControl: boolean,
        description?: string
      ) => {
        await useReportEvaluationProblem(
          documentIdRef.value,
          revisionIdRef.value,
          useChecklist.value?.id ?? "",
          useChecklist.value?.title ?? "",
          problem,
          description,
          isControl,
          checklistCriteriaIdRef.value,
          documentChecklistStore.getCriteriaFromId(checklistCriteriaIdRef.value)
        );
      };

      const checkAllCriteria = () => {
        if (evaluationResults.value == undefined) return;

        evaluationResults.value.evaluationResult.forEach((result) => {
          checkAllCriteriaRecursive(result);
        });

        processCriteria();
        saveEvaluationResults();
      };

      const checkAllCriteriaRecursive = (criteria: EvaluationCriteria) => {
        // Wrap this in an is visible or something lke that?
        if (criteria.config.isVisible == true) {
          criteria.passed = true;
          criteria.isOverriden = true;
        }

        for (const relevantInstance of criteria.relevantInstances ?? []) {
          checkAllInstancesRecursive(relevantInstance);
        }
      };

      const checkAllInstancesRecursive = (
        instance: EvaluationRelevantInstance
      ) => {
        for (const criteria of instance.criteria ?? []) {
          checkAllCriteriaRecursive(criteria);
        }
      };

      const showAllInstances = computed(() => {
        return (paragraphPosition: number) => {
          if (criteriaInstance.showAllInstances == undefined) {
            return false;
          }

          return criteriaInstance.showAllInstances[paragraphPosition] ?? false;
        };
      });

      const toggleShowAllInstances = (paragraphPosition: number) => {
        if (
          criteriaInstance.showAllInstances == undefined ||
          typeof criteriaInstance.showAllInstances == "boolean"
        ) {
          criteriaInstance.showAllInstances = {};
        }

        criteriaInstance.showAllInstances[paragraphPosition] =
          !showAllInstances.value(paragraphPosition);
        saveChecklist();
      };

      return {
        saveEvaluationResults,
        updateManualPoints,
        updateManualParagraphPoints,
        reevaluate,
        // computedEarnedPoints,
        // computedParagraphPoints,
        setInstancePosition,
        setHasReviewedInstances,
        acceptEvaluation,
        getInstanceFromToken,
        reportProblem,
        processCriteria,
        hasMetric,
        evaluationResultsObservable,
        earnedPoints,
        hasNoScore,
        hasAISuggestions,
        isLoadingResults,
        requiresReview,
        evaluationResults,
        pending,
        error,
        reviewed,
        numExpectedBodyParagraphs,
        relevantInstances,
        relevantInstancesForParagraph,
        allocatedRelevantInstances,
        didFailEvaluation,
        unallocatedInstancesForParagraph,
        criteriaConfig,
        criteriaInstance,
        requiresInstanceReview,
        hasReviewedInstances,
        hasCorrectNumberOfBodyParagraphs,
        showAllInstances,

        // Passthroguh from evaluationCriteriaInstance
        criteriaParagraphPositionsData,
        checkAllCriteria,
        toggleShowAllInstances,
      };
    }
  );
