import {
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  writeBatch,
  WriteBatch,
} from "firebase/firestore";

import debounce from "lodash.debounce";
import { Observable, Subscription, take } from "rxjs";
import {
  Checklist,
  ChecklistCriteria,
  CriteriaInstance,
  ParagraphPositionData,
  Section,
  Sections,
} from "types/Checklist";
import { EvaluationCriteria } from "types/EvaluationResults";

export const useDocumentChecklist = (documentId: string, revisionId: string) =>
  definePiniaStore(`${documentId}/${revisionId}/document/checklist`, () => {
    const useChecklist = ref<Checklist>();
    const useAllCriteriaInstances = ref<CriteriaInstance[]>([]);
    const useAllChecklistCriteria = ref<ChecklistCriteria[]>();

    const useTotalScore = ref<number>(0);
    const useTotalEarnedScore = ref<number>(0);

    const useDocumentId = ref<string>(documentId);
    const useRevisionId = ref<string>(revisionId);

    const db = useFirestore();

    const extractCriteriaIntances = (
      sections: Sections,
      paragraphPositionData?: ParagraphPositionData[]
    ): CriteriaInstance[] => {
      const allCriteriaInstances: CriteriaInstance[] = [];

      for (var section of sections) {
        var positionLabels =
          section.paragraphPositionsData ?? paragraphPositionData;

        for (var criteriaInstance of section.criteriaInstances ?? []) {
          if (
            criteriaInstance.paragraphPositionsData == undefined &&
            paragraphPositionData != undefined
          ) {
            criteriaInstance.paragraphPositionsData = JSON.parse(
              JSON.stringify(positionLabels)
            );
          }

          criteriaInstance.checklistSectionId = section.id!;
        }

        allCriteriaInstances.push(...section.criteriaInstances);
        allCriteriaInstances.push(
          ...extractCriteriaIntances(section.sections ?? [], positionLabels)
        );
      }

      return allCriteriaInstances;
    };

    const getSectionsRecursive = async (path: string): Promise<Section[]> => {
      var sectionsRef = collection(db, path);
      var sectionsQuery = query(sectionsRef, orderBy("displayOrder", "asc"));
      var sectionSnapshots = await getDocs(sectionsQuery);

      var sections: Section[] = [];

      for (var sectionSnapshot of sectionSnapshots.docs) {
        var subSections = await getSectionsRecursive(
          `${path}/${sectionSnapshot.id}/sections`
        );
        var section = sectionSnapshot.data() as Section;
        section.id = sectionSnapshot.id;
        section.sections = subSections;
        sections.push(section);
      }

      return sections;
    };

    const isInitialized = ref(false);

    const initialize = async () => {
      // console.log("Initialize the document checklist store?");

      if (isInitialized.value == true) return;

      const checklistsRef = collection(
        db,
        `/documents/${useDocumentId.value}/revisions/${useRevisionId.value}/checklists`
      );

      const checklistsQuery = query(checklistsRef, limit(1));
      const checklistResults = await getDocs(checklistsQuery);

      if (checklistResults.docs.length > 0) {
        const checklist = checklistResults.docs[0].data() as Checklist;
        checklist.id = checklistResults.docs[0].id;
        // Ok here is where I would also need to populate the checklist with all of its sections and criteria.
        var sections = await getSectionsRecursive(
          `/documents/${documentId}/revisions/${revisionId}/checklists/${checklist.id}/sections`
        );

        checklist.sections = sections;
        useChecklist.value = checklist;

        // Populate all criteria instances
        var allCriteriaInstances: CriteriaInstance[] = [];
        allCriteriaInstances.push(...checklist.criteriaInstances);
        allCriteriaInstances.push(
          ...extractCriteriaIntances(checklist.sections ?? [])
        );

        useAllCriteriaInstances.value = allCriteriaInstances;

        calculateTotalScore();

        const checklistCriteriaRef = collection(db, "checklistCriteria");
        const cheklistCriteriaResults = await getDocs(checklistCriteriaRef);

        const allCriteriaIds = allCriteriaInstances.map(
          (criteriaInstance) => criteriaInstance.checklistCriteriaId
        );

        const usableChecklistCriteria = cheklistCriteriaResults.docs.filter(
          (doc) => {
            return allCriteriaIds.includes(doc.id);
          }
        );

        useAllChecklistCriteria.value = usableChecklistCriteria.map((doc) => {
          var data = doc.data() as ChecklistCriteria;
          data.id = doc.id;

          try {
            if (data.evaluationMetricId) {
              useEvaluateChecklistCriteria(documentId, revisionId, data.id);
            }
          } catch (error) {}

          return data;
        });

        // If the checklist has a paragraph metric, we'll initialize that store and kick of the evaluation process
        if (checklist.paragraphMetricId) {
          var documentParagraphMetricStore = useDocumentParagraphMetric(
            documentId,
            revisionId
          )();
          documentParagraphMetricStore.initializeParagraphMetric(
            checklist.paragraphMetricId
          );
        }
      }

      isInitialized.value = true;
    };

    const checklistsSubscription = ref<Subscription | undefined>();

    const listenForChanged = () => {
      isInitialized.value = false;
      const checklistsRef = collection(
        db,
        `/documents/${useDocumentId.value}/revisions/${useRevisionId.value}/checklists`
      );

      checklistsSubscription.value?.unsubscribe();

      // Listen for changes to the checklists collection and essentially trigger a reinitialize of this
      checklistsSubscription.value = new Observable((subscriber) => {
        onSnapshot(checklistsRef, subscriber);
      })
        .pipe(take(1))
        .subscribe(() => {
          isInitialized.value = false;
          initialize();
        });
    };

    const reinferAllCriteria = () => {
      if (!useAllChecklistCriteria.value) return;

      for (var criteriaInstance of useAllCriteriaInstances.value) {
        const evaluationResultsStore = useResultsForCriteriaIdStore(
          documentId,
          criteriaInstance
        );

        if (!evaluationResultsStore) continue;

        evaluationResultsStore.processCriteria();
      }
    };

    const reevaluateAllCriteria = () => {
      if (!useAllChecklistCriteria.value) return;

      for (var criteriaInstance of useAllCriteriaInstances.value) {
        const evaluationResultsStore = useResultsForCriteriaIdStore(
          documentId,
          criteriaInstance
        );

        if (!evaluationResultsStore) continue;

        if (evaluationResultsStore.hasMetric) {
          evaluationResultsStore.reevaluate(true);
        }
      }
    };

    const saveSectionsRecursive = async (
      batch: WriteBatch,
      path: string,
      sections: Section[]
    ) => {
      for (var section of sections) {
        var sectionPath = `${path}/${section.id}`;
        var db = useFirestore();

        var sectionToSave = {
          ...section,
        };

        var subSections = section.sections;
        delete sectionToSave.sections;

        const sectionRef = doc(db, sectionPath);
        batch.update(sectionRef, sectionToSave);

        saveSectionsRecursive(
          batch,
          `${sectionPath}/sections`,
          subSections ?? []
        );
      }
    };

    const saveChecklist = async () => {
      try {
        const checklist = useChecklist.value;

        if (!checklist) {
          return;
        }

        const path = `/documents/${useDocumentId.value}/revisions/${useRevisionId.value}/checklists/${checklist.id}`;

        const checklistToSave = {
          ...checklist,
        };

        delete checklistToSave.sections;

        const sections = checklist.sections;

        calculateTotalScore();

        const db = useFirestore();
        const checklistRef = doc(db, path);
        const batch = writeBatch(db);
        batch.update(checklistRef, checklistToSave);
        saveSectionsRecursive(batch, `${path}/sections`, sections ?? []);

        await batch.commit();
      } catch (error) {}
    };

    const saveChecklistDebounced = debounce(saveChecklist, 500);

    const calculateTotalScore = () => {
      var totalScore = 0;
      var totalEarnedScore = 0;

      for (var checklistCriteriaInstance of useAllCriteriaInstances.value) {
        // If the checklist criteriaInstace is set to ignore then we don't add it to the total score
        if (checklistCriteriaInstance.ignore) continue;

        // I'm not going to factor in negative scores
        if (
          checklistCriteriaInstance.maxPoints &&
          checklistCriteriaInstance.maxPoints > 0
        ) {
          totalScore +=
            typeof checklistCriteriaInstance.maxPoints == "string"
              ? parseInt(checklistCriteriaInstance.maxPoints)
              : checklistCriteriaInstance.maxPoints;
        }

        if (checklistCriteriaInstance.earnedPoints) {
          totalEarnedScore += checklistCriteriaInstance.earnedPoints;
        }
      }

      if (useChecklist.value?.isLateDeduction) {
        totalEarnedScore += useChecklist.value?.isLateDeduction;
      }

      if (useChecklist.value?.customCriteriaInstances) {
        for (var customCriteriaInstance of useChecklist.value
          ?.customCriteriaInstances) {
          if (customCriteriaInstance.earnedPoints) {
            totalEarnedScore += customCriteriaInstance.earnedPoints;
          }
        }
      }

      useTotalScore.value = totalScore;
      useTotalEarnedScore.value = totalEarnedScore;
    };

    const getCriteriaFromId = (id: string): ChecklistCriteria | undefined => {
      return (useAllChecklistCriteria.value ?? []).find((c) => c.id === id);
    };

    const updateLateScore = (newValue: any) => {
      let value = newValue;

      if (typeof value == "string") {
        value = parseInt(value);

        if (isNaN(value)) {
          return;
        }
      }

      if (useChecklist.value) {
        useChecklist.value.isLateDeduction = value;
        saveChecklist();
      }
    };

    // We want to store mechanics errors collected from multiple criteria in a single place
    // We'll store than in the checklist. ultimately these are evaluationCriteria
    const mechanicsCriteria = computed(() => {
      if (!useChecklist.value) return [];

      var criteria = useChecklist.value.mechanicsCriteria ?? [];

      return criteria.filter((c) => c.passed == false);
    });

    // Whenever the state of a mechanics criteria changes we'll push it here and add it if it's not added then save the checklist
    const onMechanicsCriteriaUpdated = async (criteria: EvaluationCriteria) => {
      if (!useChecklist.value) return;

      if (!useChecklist.value.mechanicsCriteria) {
        useChecklist.value.mechanicsCriteria = [];
      }

      const existingCriteria = useChecklist.value.mechanicsCriteria?.find(
        (c) => c.instanceUid === criteria.instanceUid
      );

      if (existingCriteria) {
        existingCriteria.passed = criteria.passed;
        existingCriteria.relevantInstances = criteria.relevantInstances;
      } else {
        useChecklist.value.mechanicsCriteria?.push(criteria);
      }

      await saveChecklist();
    };

    const documentAnnotationsStore = useDocumentAnnotationsStore(documentId);
    const { annotationWithDeductions } = storeToRefs(documentAnnotationsStore);

    // We also want to track annotations that are being flagged as a revision deduction
    const mechanicsAnnotations = computed(() => {
      return annotationWithDeductions.value.map((a) => a.id!);
    });

    const defaultParagraphPositionsData = computed(
      (): ParagraphPositionData[] | undefined => {
        return useChecklist.value?.paragraphPositionsData;
      }
    );
    const checklistLevelId = computed(() => {
      if (!useChecklist.value) return;

      return useChecklist.value.levelId;
    });

    const checklistLessonId = computed(() => {
      if (!useChecklist.value) return;

      return useChecklist.value.lessonId;
    });

    const isOSS = computed(() => {
      if (!useChecklist.value) return;

      return useChecklist.value.levelName?.includes("OSS");
    });

    const hasMechanicsSummary = computed(() => {
      if (useChecklist.value == undefined) return false;

      if (useAllChecklistCriteria.value == undefined) return false;

      const criteriaWithSummary = useAllChecklistCriteria.value?.filter(
        (c) => c.isMechanicsCriteria
      );
      return criteriaWithSummary.length > 0;
    });

    const documentEvaluativeParagraphsStore =
      useDocumentEvaluativeParagraphsStore(documentId);
    const { numBodyParagraphs } = storeToRefs(
      documentEvaluativeParagraphsStore
    );

    const hasCorrectNumberOfBodyParagraphs = computed(() => {
      if (!useChecklist.value) return true;
      return (
        numBodyParagraphs.value == numExpectedBodyParagraphs.value ||
        numExpectedBodyParagraphs.value == 1
      );
    });

    const numExpectedBodyParagraphs = computed(() => {
      return useChecklist.value?.numParagraphs ?? 0;
    });

    const hasShownMissmatchMessage = ref(false);

    const submittedDocumentStore = useSubmittedDocumentStore(documentId);
    const { submittedDocument } = storeToRefs(submittedDocumentStore);

    watch(useTotalEarnedScore, () => {
      if (submittedDocument.value == undefined) return;

      submittedDocument.value.earnedPoints = useTotalEarnedScore.value;

      submittedDocumentStore.saveDocumentDebounced();
    });

    return {
      initialize,
      listenForChanged,
      saveChecklist,
      getCriteriaFromId,
      calculateTotalScore,
      updateLateScore,
      useChecklist,
      useAllCriteriaInstances,
      useAllChecklistCriteria,
      useTotalScore,
      useTotalEarnedScore,
      isInitialized,

      mechanicsCriteria,
      onMechanicsCriteriaUpdated,

      mechanicsAnnotations,

      checklistLevelId,
      checklistLessonId,

      reinferAllCriteria,
      reevaluateAllCriteria,

      defaultParagraphPositionsData,

      isOSS,

      hasMechanicsSummary,
      hasCorrectNumberOfBodyParagraphs,
      numExpectedBodyParagraphs,
      hasShownMissmatchMessage,
    };
  });
